/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

// remove IE11 polyfills
// // with angular/fire
// // https://github.com/angular/angularfire#polyfills
// // IE11 needs globalthis
// import 'globalthis/auto';
// // IE11 needs proxy
// import 'proxy-polyfill/proxy.min.js';
// // IE11 needs indexeddb.getall()
// import 'indexeddb-getall-shim';
// // IE still fails
// // @firebase/auth: Auth (9.0.0): INTERNAL ASSERTION FAILED:
// // Could not find Headers implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill
// // firebase doc says need these two:
// import 'core-js/stable';
// import 'cross-fetch/polyfill';

// the es6 polyfills are not necessary because we target es5.

// IE9, IE10 and IE11 requires all of the following polyfills.
// not required when "es5BrowserSupport": true
// since 301, need import /es instead of /es6
// import 'core-js/es/symbol';
// import 'core-js/es/object';
// import 'core-js/es/function';
// import 'core-js/es/parse-int';
// import 'core-js/es/parse-float';
// import 'core-js/es/number';
// import 'core-js/es/math';
// import 'core-js/es/string';
// import 'core-js/es/date';
// import 'core-js/es/array';
// import 'core-js/es/regexp';
// import 'core-js/es/map';
// import 'core-js/es/set';

// IE10 and IE11 requires the following for the Reflect API.
// not required when "es5BrowserSupport": true
// import 'core-js/es6/reflect';


// Evergreen browsers require these.
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
// import 'core-js/es7/reflect';



/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
// import './zone-flags'; // https://stackoverflow.com/questions/55047818/angular-ie11-not-working-getting-script1002-syntax-error
import 'zone.js';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// no need for intl in angular5
// import 'intl';  // Run `npm install --save intl`.

/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';
